<template>
  <div
    :id="index"
    class="question__body"
  >
    <div class="question__form">
      <div class="question__form-title">
        <ValidationProvider
          v-slot="{ errors, classes }"
          rules="required"
          :name="'title-' + index"
        >
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="{
              'Debés ingresar una pregunta válida': errors[0],
            }"
          >
            <b-input
              :ref="'formtitle-' + index"
              :key="'title-input-' + index"
              v-model="task.title"
              placeholder="Ingresá una pregunta"
              custom-class="form-title"
              :name="'title-' + index"
              :class="classes"
              maxlength="200"
              :has-counter="false"
            />
          </b-field>
        </ValidationProvider>
        <div
          v-if="!isSES"
          class="title-actions"
        >
          <b-tooltip
            :label="actionMediaLabel"
            position="is-bottom"
            type="is-dark"
            :delay="300"
          >
            <b-button
              class="media"
              :class="{ 'disabled': isMediaLoading }"
              @click="openMediaWidget"
            />
          </b-tooltip>
        </div>
      </div>

      <media
        :model="task"
        type="question"
        :media-width="900"
      />

      <div
        v-if="isDescriptionVisible"
        class="question__form-description"
      >
        <b-field>
          <quill-editor
            ref="taskDescriptionQuill"
            :key="`description-input-${index}`"
            class="inline-quill-editor"
            :content="taskDescription"
            :name="`description-${index}`"
            :options="quillOptions"
            placeholder="Ingresá una descripción (opcional)"
            @change="onQuillChange(index, $event)"
          />
        </b-field>
        <div
          class="remove-description-button"
          @click="removeDescription()"
        />
      </div>

      <div class="question__form-choice">
        <p>Tipo de pregunta:</p>
        <div class="block">
          <b-radio
            v-for="question in questionTypes"
            :key="question.taskType"
            v-model="task.taskType"
            :native-value="question.taskType"
            :checked="task.taskType == question.taskType"
          >
            {{ question.label }}
          </b-radio>
        </div>

        <div
          class="questionType"
          :class="selectedTypeClassCSS"
        >
          {{ selectedType }}
        </div>

        <b-dropdown aria-role="list">
          <button
            slot="trigger"
            class="button"
            @click.prevent
          >
            <span>Cambiar</span>
          </button>

          <b-dropdown-item
            v-for="item in questionTypes"
            :key="item.taskType"
            :class="item.taskType"
            aria-role="listitem"
            @click="selectTaskType(item.taskType, task.id)"
          >
            {{ item.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <QuestionDetail
        :task.sync="task"
        :index="index"
      />

      <div
        v-if="isRating"
        class="icon-selector"
      >
        <div class="text">
          Indicador del puntaje:&nbsp;
        </div>
        <div class="icon-selected">
          <img :src="require(`../assets/question/icon-question-rating-skin-${task.icons || defaultRatingIcon}.svg`)">
        </div>
        <b-dropdown
          v-model="task.icons"
          aria-role="list"
        >
          <template #trigger>
            Elegir otro
          </template>

          <b-dropdown-item
            v-for="(item, i) in ratingIcons"
            :key="i"
            :value="item.label"
            aria-role="listitem"
            @click="setRatingIcons(task.icons)"
          >
            <img :src="require(`../assets/question/icon-question-rating-skin-${item.label}.svg`)">
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <QuestionFooter
        :task.sync="task"
        :index="index"
        :total="total"
        :is-description-visible="isDescriptionVisible"
        @delete-media="unsetTaskMedia"
        @show-description="showDescription"
      />
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

import CloudinaryTexts from '@/cloudinaryTexts.json'
import QuestionDetail from '@/components/ActivityOpenQuestionItemDetail.vue'
import QuestionFooter from '@/components/ActivityOpenQuestionItemFooter.vue'
import Media from '@/components/Media.vue'
import SharedConstants from '@/shared/constants.json'
import { ValidationProvider } from 'vee-validate'

import { formattedTextQuillOptions, quillDataStringToHTML } from '@/quill'

export default {
  name: 'ActivityOpenQuestionItem',
  components: {
    quillEditor,
    QuestionDetail,
    QuestionFooter,
    Media,
    ValidationProvider
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    updateTaskDescription: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      questionTypes: [
        {
          taskType: SharedConstants.TASK_TYPE.OPEN_QUESTION,
          label: 'Respuesta Libre'
        },
        {
          taskType: SharedConstants.TASK_TYPE.SIMPLE_CHOICE,
          label: 'Opción Simple'
        },
        {
          taskType: SharedConstants.TASK_TYPE.MULTIPLE_CHOICE,
          label: 'Opción Múltiple'
        },
        {
          taskType: SharedConstants.TASK_TYPE.NPS,
          label: 'Net Promoter Score (NPS)'
        },
        /*         {
          taskType: SharedConstants.TASK_TYPE.SES,
          label: 'Nivel Socioeconómico (NSE)'
        }, */
        {
          taskType: SharedConstants.TASK_TYPE.RATING,
          label: 'Puntuación'
        }
      ],

      ratingIcons: [
        {
          iconType: SharedConstants.RATING_TASK_ICON.STAR,
          label: 'star'
        },
        {
          iconType: SharedConstants.RATING_TASK_ICON.RADIO_BUTTON,
          label: 'radio_button'
        },
        {
          iconType: SharedConstants.RATING_TASK_ICON.LIKE,
          label: 'like'
        },
        {
          iconType: SharedConstants.RATING_TASK_ICON.HEART,
          label: 'heart'
        }
      ],

      isMounted: false,
      isDescriptionVisible: false,
      isMediaLoading: false,
      optionsNeedToBeCleaned: false,
      quillSetupInterval: null
    }
  },

  computed: {
    actionMediaLabel() {
      return `${this.task.mediaFile != null ? 'Cambiar' : 'Agregar'} imagen o video`
    },

    selectedType() {
      let selected

      if (this.task.taskType === SharedConstants.TASK_TYPE.OPEN_QUESTION) {
        selected = 'Respuesta Libre'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.SIMPLE_CHOICE) {
        selected = 'Opción Simple'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.MULTIPLE_CHOICE) {
        selected = 'Opción Múltiple'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.NPS) {
        selected = 'Net Promoter Score (NPS)'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.SES) {
        selected = 'Nivel Socioeconómico (NSE)'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.RATING) {
        selected = 'Puntuación'
      }

      return selected
    },

    selectedTypeClassCSS() {
      let selected
      if (this.task.taskType === SharedConstants.TASK_TYPE.OPEN_QUESTION) {
        selected = 'openquestion'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.SIMPLE_CHOICE) {
        selected = 'simple'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.MULTIPLE_CHOICE) {
        selected = 'multiple'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.NPS) {
        selected = 'nps'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.SES) {
        selected = 'ses'
      } else if (this.task.taskType === SharedConstants.TASK_TYPE.RATING) {
        selected = 'rating'
      }

      return selected
    },

    isRating() {
      return this.task.taskType === SharedConstants.TASK_TYPE.RATING
    },

    isSES() {
      return this.task.taskType === SharedConstants.TASK_TYPE.SES
    },

    defaultRatingIcon() {
      return SharedConstants.RATING_TASK_ICON.STAR
    },

    quillOptions() {
      return formattedTextQuillOptions
    },
    taskDescription() {
      if (this.task.description) {
        return quillDataStringToHTML(this.task.description)
      }

      return ''
    }
  },

  mounted() {
    this.isMounted = true
    this.isDescriptionVisible = this.taskDescription !== ''
    this.optionsNeedToBeCleaned = this.task.taskType === SharedConstants.TASK_TYPE.NPS
      || this.task.taskType === SharedConstants.TASK_TYPE.SES

    // Set watchers for mouse actions on Quill editor
    this.quillSetupInterval = setInterval(() => {
      if (this.$refs.taskDescriptionQuill !== undefined) {
        clearInterval(this.quillSetupInterval)
        this.quillSetupInterval = null

        this.$refs.taskDescriptionQuill.$el.querySelector('.ql-editor').setAttribute(
          // Workaround: placeholder not being set correctly: https://github.com/surmon-china/vue-quill-editor/issues/48
          'data-placeholder', 'Ingresá una descripción (opcional)'
        )

        this.$refs.taskDescriptionQuill.$el.addEventListener('mouseenter', () => {
          this.$refs.taskDescriptionQuill.$el.classList.add('hover')
        })

        this.$refs.taskDescriptionQuill.$el.addEventListener('mouseleave', () => {
          this.$refs.taskDescriptionQuill.$el.classList.remove('hover')
        })

        this.$refs.taskDescriptionQuill.$el.querySelector('.ql-editor').addEventListener('focus', () => {
          this.$refs.taskDescriptionQuill.$el.classList.add('focus')
        })

        this.$refs.taskDescriptionQuill.$el.querySelector('.ql-editor').addEventListener('blur', () => {
          this.$refs.taskDescriptionQuill.$el.classList.remove('focus')
        })
      }
    })
  },
  beforeDestroy() {
    if (this.quillSetupInterval !== null) {
      clearInterval(this.quillSetupInterval)
    }
  },
  methods: {
    setTaskMedia(mediaInfo) {
      this.$set(this.task, 'mediaFile',
        {
          publicId: mediaInfo.public_id,
          width: mediaInfo.width,
          height: mediaInfo.height,
          type: mediaInfo.type,
          resourceType: mediaInfo.resource_type,
          version: mediaInfo.version,
          format: mediaInfo.format
        })
    },

    unsetTaskMedia() {
      this.task.mediaFile = null
    },

    showDescription() {
      this.isDescriptionVisible = true
    },

    removeDescription() {
      this.task.description = ''
      this.isDescriptionVisible = false
    },

    setRatingIcons(icon) {
      this.task.icons = icon
    },

    // Set "task_type" to "Task" Prop (Activity object)
    selectTaskType(type) {
      this.task.taskType = type
      if (type === SharedConstants.TASK_TYPE.OPEN_QUESTION) {
        this.task.options = []
        this.optionsNeedToBeCleaned = false
      } else if (type === SharedConstants.TASK_TYPE.NPS) {
        this.task.options = [
          { order: 1, description: '1', details: 'Nada probable' },
          { order: 2, description: '2' },
          { order: 3, description: '3' },
          { order: 4, description: '4' },
          { order: 5, description: '5' },
          { order: 6, description: '6' },
          { order: 7, description: '7' },
          { order: 8, description: '8' },
          { order: 9, description: '9' },
          { order: 10, description: '10', details: 'Muy probable' }
        ]
        this.optionsNeedToBeCleaned = true
      } else if (type === SharedConstants.TASK_TYPE.SES) {
        this.task.options = [
          { order: 1, description: 'E' },
          { order: 2, description: 'D2' },
          { order: 3, description: 'D1' },
          { order: 4, description: 'C3' },
          { order: 5, description: 'C2' },
          { order: 6, description: 'C1' },
          { order: 7, description: 'AB' }
        ]
        this.task.required = true
        this.task.title = 'Nivel Socioeconómico (NSE)'
        this.optionsNeedToBeCleaned = true
      } else if ((type === SharedConstants.TASK_TYPE.SIMPLE_CHOICE)
      || (type === SharedConstants.TASK_TYPE.MULTIPLE_CHOICE)
      || (type === SharedConstants.TASK_TYPE.RATING)) {
        if (this.optionsNeedToBeCleaned) {
          this.task.options = []
          this.optionsNeedToBeCleaned = false
        }
      }
    },

    openMediaWidget() {
      this.isMediaLoading = true
      window.cloudinary.createUploadWidget({
        upload_preset: 'mvp_preset',
        folder: 'question-media',
        cropping: true,
        multiple: false,
        language: 'es',
        text: CloudinaryTexts
      }, (error, result) => {
        this.enableMediaButton()
        if (!error && result && result.event === 'success') {
          this.setTaskMedia(result.info)
        }
      }).open()
    },

    enableMediaButton() {
      this.isMediaLoading = false
    },

    onQuillChange(taskIndex, event) {
      this.updateTaskDescription(taskIndex, event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/activityopenquestionitem_scoped.scss";
</style>
<style lang="scss">
@import "../scss/activityopenquestionitem.scss";
</style>
