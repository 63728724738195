<template>
  <div class="container is-widescreen small-extra-top-padding">
    <div class="columns is-centered">
      <div class="column is-8 page-bottom-padding-extra">
        <b-loading
          v-if="isLoading"
          :active="true"
          :is-full-page="isFullPage"
          :can-cancel="false"
        />
        <div v-else>
          <template v-if="step === 1">
            <div class="header-bar">
              <div class="section-title">
                <h1>{{ projectName }}</h1>
              </div>
            </div>

            <ValidationObserver ref="formintro">
              <div class="questions">
                <div class="questions__description">
                  <p class="questions__subtitle">
                    Título del cuestionario
                  </p>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    rules="required"
                    name="name"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0] }"
                      :message="{
                        'Ingresá un título para la actividad': errors[0],
                      }"
                    >
                      <b-input
                        v-model="activityData.name"
                        type="text"
                        maxlength="80"
                        name="name"
                        :class="classes"
                        placeholder="Ingresá un título"
                        @input="removeError('title')"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="questions__description">
                  <p class="questions__subtitle">
                    Descripción del cuestionario
                  </p>
                  <b-field>
                    <quill-editor
                      ref="activityDescriptionQuill"
                      :content="activityDescription"
                      name="description"
                      :options="quillOptions"
                      placeholder="Ingresá una descripción"
                      :class="quillEditorClasses"
                      @change="onActivityDescriptionQuillChange($event)"
                    />
                  </b-field>
                </div>

                <div class="questions__description hidden">
                  <p class="questions__subtitle">
                    Ingresá una imagen de portada (Opcional)
                  </p>
                  <b-field>
                    <b-input placeholder="ICON + Exportar IMGs" />
                  </b-field>
                </div>

                <div class="questions__description hidden">
                  <p class="questions__subtitle">
                    Ingresá el link a un video (Opcional)
                  </p>
                  <b-field>
                    <b-input
                      placeholder="Ejemplo: www.youtube.com/video"
                    />
                  </b-field>
                </div>
              </div>
            </ValidationObserver>
          </template>

          <template v-if="step === 2">
            <div class="header-bar">
              <div class="section-title">
                <h1>{{ projectName }}</h1>
              </div>
            </div>

            <ValidationObserver ref="formbuilder">
              <div class="questions">
                <p class="questions__subtitle">
                  {{ questionSubtitle }}
                </p>
                <activity-open-question-item
                  v-for="(task, index) in activityData.tasks"
                  :key="index"
                  ref="question"
                  :task="task"
                  :index="index"
                  :total="totalTasks"
                  :update-task-description="updateTaskDescription"
                  @delete-task="deleteTask"
                  @duplicate-task="duplicateTask"
                  @move-task="moveTask"
                />
              </div>
            </ValidationObserver>

            <b-notification
              v-model="isTasksExist"
              indefinite="true"
              type="is-danger"
              aria-close-label="Cerrar notificación"
            >
              <strong>Error:</strong> Debes crear al menos 1 pregunta.
            </b-notification>

            <button
              class="new-question"
              @click="addNewTask(activityData)"
            >
              <span />
              Nueva pregunta
            </button>
          </template>

          <template v-if="step === 3">
            <div class="header-bar">
              <div class="section-title">
                <h1>Previsualización del proyecto</h1>
              </div>
            </div>

            <ValidationObserver ref="formpreview">
              <div class="preview">
                <div class="preview__section">
                  <div class="preview__section-name">
                    Sección 1
                  </div>
                  <div class="preview__section-line" />

                  <div
                    v-if="isCoverImage"
                    class="preview__item"
                  >
                    <div class="preview__item-title">
                      Foto de Portada
                    </div>
                    <div class="preview__item-field">
                      <cld-image
                        cloud-name="relevant"
                        :public-id="mediaPublicId"
                        class="cover"
                        crop="scale"
                        secure="true"
                      >
                        <cld-transformation radius="8" />
                      </cld-image>
                    </div>
                  </div>

                  <div class="preview__item">
                    <div class="preview__item-title">
                      Título
                    </div>
                    <div class="preview__item-field">
                      <ValidationProvider
                        v-slot="{ errors, classes }"
                        rules="required"
                        name="title-preview"
                      >
                        <b-field
                          :type="{ 'is-danger': errors[0] }"
                          :message="{
                            'Ingresá un título para la actividad.': errors[0],
                          }"
                        >
                          <b-input
                            v-model="activityData.name"
                            name="title-preview"
                            placeholder="Ingresá un título"
                            custom-class="preview-title"
                            :class="classes"
                          />
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="preview__item">
                    <div class="preview__item-title">
                      Descripción
                    </div>
                    <div class="preview__item-field">
                      <quill-editor
                        ref="activityPreviewDescriptionQuill"
                        :content="activityDescription"
                        name="description"
                        :options="quillOptions"
                        placeholder="Ingresá una descripción"
                        :class="quillEditorClasses"
                        @change="onActivityPreviewDescriptionQuillChange($event)"
                      />
                    </div>
                  </div>
                </div>

                <div class="preview__section">
                  <div class="preview__section-name">
                    Sección 2
                  </div>
                  <div class="preview__section-line" />

                  <div class="preview__item">
                    <div class="preview__item-title">
                      Preguntas
                    </div>
                    <div class="preview__item-form">
                      <task-preview
                        v-for="(task) in activityData.tasks"
                        :key="task.id"
                        :task="task"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </template>

          <template v-if="step === 4">
            <div class="finished">
              <div class="finished__img">
                <img src="../assets/finished-project.png">
              </div>
              <div class="finished__title">
                ¡Tu proyecto fue publicado con éxito!
              </div>
              <div class="finished__actions">
                <router-link
                  class="button"
                  :to="{ name: 'proyectos' }"
                >
                  Ir a Proyectos
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SharedConstants from '@/shared/constants.json'
import { mapState } from 'vuex'
import ActivityOpenQuestionItem from '@/components/ActivityOpenQuestionItem.vue'
import TaskPreview from '@/components/TaskPreview.vue'
import { CldImage, CldTransformation } from 'cloudinary-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'

import { formattedTextQuillOptions, quillDataToString, quillDataStringToHTML } from '@/quill'

export default {
  name: 'ActivityOpenQuestion',
  components: {
    ActivityOpenQuestionItem,
    TaskPreview,
    CldImage,
    CldTransformation,
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  data() {
    return {
      activityData: {},

      formErrors: [],
      isTasksExist: false,

      // Loading
      isLoading: true,
      isFullPage: false,

      quillHovered: false,
      quillFocused: false,
      quillSetupInterval: null
    }
  },
  computed: {
    ...mapState('staffActivities', ['activitiesDataCA', 'step']),
    ...mapState('Projects', ['projectData']),
    isLastStep() {
      if (this.step === 4) {
        return true
      }
      return false
    },
    questionSubtitle() {
      return this.activityData.tasks.length > 0
        ? 'Preguntas'
        : 'Aún no hay preguntas, ¡Creá la primera!'
    },
    totalTasks() {
      return this.activityData.tasks.length
    },
    projectName() {
      return this.projectData.name
    },
    isCoverImage() {
      return this.activityData.coverImage !== null
    },
    mediaPublicId() {
      return this.activityData.coverImage !== null ? this.activityData.coverImage.publicId : null
    },
    quillOptions() {
      return {
        ...formattedTextQuillOptions,
        placeholder: 'Ingresa una descripción'
      }
    },
    activityDescription() {
      if (this.activityData.description) {
        return quillDataStringToHTML(this.activityData.description)
      }

      return ''
    },
    quillEditorClasses() {
      const classes = ['form-quill-editor']

      if (this.quillEditorHovered) classes.push('hovered')
      if (this.quillEditorFocused) classes.push('focused')

      return classes
    }
  },

  watch: {
    isTasksExist() {
      if (this.isTasksExist) {
        setTimeout(() => {
          this.isTasksExist = false
        }, 3000)
      }
    }
  },
  async created() {
    // Reset Step to "1"
    this.$store.dispatch('staffActivities/resetStep')

    const { projectIdentifier, activityIdentifier } = this.$route.params

    // Get Project Data
    await this.$store.dispatch('Projects/getProjectData', { projectIdentifier, edit: true })

    // Init spinner loading
    this.isLoading = true

    // Get Activity of type "Open Question"
    this.$store.dispatch(
      'staffActivities/getActivityData', { projectIdentifier, activityIdentifier }
    ).then(
      (data) => {
        this.activityData = JSON.parse(JSON.stringify(data))
        this.isLoading = false
      }
    ).catch(
      (err) => {
        console.log(err)
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Error: No se pudo cargar la Actividad.',
          position: 'is-top',
          type: 'is-danger'
        })
        this.isLoading = false
      }
    )
  },

  mounted() {
    // On PREV step
    this.$root.$on('validateStepFields', () => {
      this.validateStepFields()
    })

    // On NEXT step
    this.$root.$on('updateActivity', async () => {
      const validation = await this.validateFields()

      if (validation) {
        this.updateActivityData(this.activityData)
      }
    })

    // Set watchers for mouse actions on Quill editor
    this.quillSetupInterval = setInterval(() => {
      if (this.$refs.activityDescriptionQuill !== undefined) {
        clearInterval(this.quillSetupInterval)
        this.quillSetupInterval = null

        this.$refs.activityDescriptionQuill.$el.addEventListener('mouseenter', () => {
          this.$refs.activityDescriptionQuill.$el.classList.add('hover')
        })

        this.$refs.activityDescriptionQuill.$el.addEventListener('mouseleave', () => {
          this.$refs.activityDescriptionQuill.$el.classList.remove('hover')
        })

        this.$refs.activityDescriptionQuill.$el.querySelector('.ql-editor').addEventListener('focus', () => {
          this.$refs.activityDescriptionQuill.$el.classList.add('focus')
        })

        this.$refs.activityDescriptionQuill.$el.querySelector('.ql-editor').addEventListener('blur', () => {
          this.$refs.activityDescriptionQuill.$el.classList.remove('focus')
        })
      }
    }, 1000)
  },

  beforeDestroy() {
    this.$root.$off('updateActivity')
    this.$root.$off('validateStepFields')

    if (this.quillSetupInterval !== null) {
      clearInterval(this.quillSetupInterval)
    }
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },

    // Add Task from local object (not by API)
    addNewTask(activity) {
      this.isTasksExist = false

      this.activityData.tasks.push({
        activityId: activity.id,
        taskType: SharedConstants.TASK_TYPE.OPEN_QUESTION,
        order: this.activityData.tasks.length + 1,
        required: true,
        title: '',
        description: '',
        options: [],
        icons: SharedConstants.RATING_TASK_ICON.STAR
      })

      // Set Focus() on Title of last element added after Add New Task
      this.$nextTick(() => {
        const index = this.activityData.tasks.length - 1
        this.$refs.question[index].$el.querySelector('.input').focus()
      })
    },

    // Duplicate task from local object (not by API)
    duplicateTask(task) {
      if (task) {
        // Find index of the Task in the array
        const taskIndex = this.activityData.tasks.indexOf(task)
        // Create a new object from "task"
        const newTask = JSON.parse(JSON.stringify(task))
        // Remove "id" property on the new object (remove reactivity).
        if (newTask.id) {
          delete newTask.id
        }
        // Remove the "id" properties from the Options of the new object (remove reactivity).
        if (newTask.options.length > 0) {
          newTask.options.map((item) => delete item.id)
        }
        // Add the new Object to array, from the cloned task
        this.activityData.tasks.splice(taskIndex + 1, 0, newTask)

        // Show toast buefy
        this.$buefy.toast.open({
          duration: 2500,
          message: 'La pregunta fue duplicada correctamente',
          position: 'is-top',
          type: 'is-success'
        })
      }
    },

    // Move UP/DOWN task
    moveTask(task, direction) {
      const directionSelected = direction === 'down' ? 1 : -1

      if (task) {
        // Find index of the Task in the array
        const taskIndex = this.activityData.tasks.indexOf(task)
        // Create a copy of the "task" object
        const taskCopy = JSON.parse(JSON.stringify(task))

        if (
          !(taskIndex === 0 && directionSelected === -1)
          && !(
            taskIndex === this.activityData.tasks.length - 1
            && directionSelected === 1
          )
        ) {
          this.activityData.tasks.splice(taskIndex, 1)
          this.activityData.tasks.splice(
            taskIndex + directionSelected,
            0,
            taskCopy
          )
        }
      }
    },

    // Remove task from local object (not by API)
    deleteTask(task) {
      if (task) {
        // Find index of the Task in the array
        const taskIndex = this.activityData.tasks.indexOf(task)
        if (taskIndex !== -1) {
          // Delete Task from 'taskIndex'
          this.activityData.tasks.splice(taskIndex, 1)
          // Show toast buefy
          this.$buefy.toast.open({
            duration: 1500,
            message: 'Pregunta eliminada correctamente',
            position: 'is-top',
            type: 'is-success'
          })
        }
      }
    },

    // Set the questions order according to the position of each element (using index)
    setTaskOrder(activity) {
      if (activity) {
        activity.tasks.forEach((item, index) => { item.order = index + 1 })
      }
    },

    // Update the entire object by pressing the "Continue" button, and go to next Step
    updateActivityData(activity) {
      // If tasks not exist on Step 3, show error message
      if (this.step === 2 && activity.tasks.length === 0) {
        this.isTasksExist = true
        return
      }

      this.isLoading = true

      this.setTaskOrder(activity)

      this.validateFields()
        .then(() => {
          if (activity) {
            delete activity.participations // Delete property for make PUT (updateActivityData)

            this.$store
              .dispatch('staffActivities/updateActivityData', {
                projectIdentifier: this.projectData.identifier,
                activityData: activity
              })
              .then(() => {
                this.scrollToTop()

                // If Activity is OK, run NextStep
                if (!this.isLastStep) {
                  this.$store.dispatch('staffActivities/nextStep')
                }

                // If STEP = 4 => update PROJECT STATUS
                if (this.step === 4) {
                  this.projectData.status = SharedConstants.PROJECT_STATUS.ACTIVE
                  this.$store
                    .dispatch('Projects/updateProjectStatus', this.projectData)
                    .then(() => {
                      this.isLoading = false
                    }).catch((err) => {
                      console.log(err)
                      this.$buefy.toast.open({
                        duration: 3000,
                        message: 'Error: No se pudo guardar su Proyecto.',
                        position: 'is-top',
                        type: 'is-danger'
                      })
                      this.isLoading = false
                    })
                } else {
                  this.isLoading = false
                }
              })
              .catch((err) => {
                console.log(err)
                this.$buefy.toast.open({
                  duration: 3000,
                  message: 'Error: Su progreso no fue guardado.',
                  position: 'is-top',
                  type: 'is-danger'
                })
              })
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 2500,
            message: 'Completá los campos para continuar',
            position: 'is-bottom-right',
            type: 'is-danger'
          })
        })
    },

    // Validate if the form has no errors, to allow going back (Anterior)
    validateStepFields() {
      this.validateFields()
        .then(() => {
          this.isTasksExist = false
          this.scrollToTop()
          this.$store.dispatch('staffActivities/prevStep')
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 2500,
            message: 'Completá los campos para retroceder',
            position: 'is-bottom-right',
            type: 'is-danger'
          })
        })
    },

    // Validate Form Groups when is called
    validateFields() {
      // Validate for "formintro" ValidationObserver
      if (this.step === 1) {
        return new Promise((resolve, reject) => {
          this.$refs.formintro.validate().then((success) => {
            if (success) {
              resolve(success)
            } else {
              // Scroll to the first error
              this.scrollToError('.invalid > .is-danger')
              reject(success)
            }
          })
        })
      }

      // Validate for "formbuilder" ValidationObserver
      if (this.step === 2) {
        return new Promise((resolve, reject) => {
          this.$refs.formbuilder.validate().then((success) => {
            if (success) {
              resolve(success)
            } else {
              this.scrollToError('.invalid > input')
              reject()
            }
          })
        })
      }

      // Validate for "formpreview" ValidationObserver
      if (this.step === 3) {
        return new Promise((resolve, reject) => {
          this.$refs.formpreview.validate().then((success) => {
            if (success) {
              resolve(success)
            } else {
              this.scrollToError('.invalid > .is-danger')
              reject(success)
            }
          })
        })
      }

      return null
    },

    // Scroll to the first error
    scrollToError(selector) {
      this.$nextTick(() => {
        const element = this.$el.querySelectorAll(selector)[0]

        if (element !== undefined) {
          element.focus()
        }
      })
    },
    // Run when the user enters a value in the field
    removeError(name) {
      const elem = this.formErrors.indexOf(name)

      if (elem !== -1) {
        this.formErrors.splice(elem, 1)
      }
    },
    onActivityDescriptionQuillChange(event) {
      this.activityData.description = quillDataToString(event.quill.getContents(), event.html)
    },
    onActivityPreviewDescriptionQuillChange(event) {
      this.activityData.description = quillDataToString(event.quill.getContents(), event.html)
    },
    updateTaskDescription(taskIndex, event) {
      this.activityData.tasks.[taskIndex].description = quillDataToString(event.quill.getContents(), event.html)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  .section-title {
    h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.questions {
  text-align: left;

  .questions__subtitle {
    font-size: 18px;
    font-weight: 500;
    color: $black;
    margin-bottom: 7px;
  }

  .questions__body:focus {
    border: 1px solid $black;
  }

  .questions__description {
    margin-bottom: 30px;

    &.hidden {
      display: none;
    }
  }
}

.new-question {
  width: 100%;
  height: 60px;
  background: $white;
  border: 1px dashed $blue;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $blue;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 17px rgb(74 74 74 / 16%);
    border: 1px dashed $gray-dark;
    transition: all 0.2s ease-in-out;
  }

  &:active,
  &:focus {
    outline: none;
  }

  span {
    width: 22px;
    height: 22px;
    background: url("../assets/activity/icon-activity-add.svg") center center
      no-repeat;
    background-size: 22px;
    display: inline-block;
    margin-right: 11px;
  }
}

.preview {
  text-align: left;

  .preview__section {
    .preview__section-name {
      font-size: 13px;
      color: #d2d2d2;
      text-transform: uppercase;
      position: relative;
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 20px;

      &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 100%;
        height: 1px;
        content: "\a0";
        background-color: #d2d2d2;
        margin-left: 11px;
      }
    }
  }

  .preview__item {
    .preview__item-title {
      font-size: 18px;
      font-weight: 500;
      color: $black;
      margin-bottom: 7px;
    }

    .preview__item-field {
      margin-bottom: 15px;

      .field {
        box-shadow: $box-shadow;
      }

      img {
        width: 100%;
        box-shadow: $box-shadow;
      }

      .cover {
        img {
          width: 100%;
          box-shadow: $box-shadow;
        }
      }
    }
  }
}

.finished {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-top: 68px;

  .finished__img {
    height: 256px;
    display: block;
    margin-bottom: 35px;
    img {
      width: 320px;
    }
  }

  .finished__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: $black;
    margin-bottom: 15px;
  }

  .finished__text {
    max-width: 490px;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    color: $black;
    margin-bottom: 32px;
  }

  .finished__actions {
    .button {
      background: $green;
      border-radius: 50px;
      border: none;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      color: $white;
      min-width: 176px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #31b975;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>

<style lang="scss">
.preview__item-field,
.questions__description {
  .field {
    .control {
      &.has-icons-right {
        .icon {
          height: 100% !important;
        }
      }

      .input {
        box-shadow: $box-shadow;

        &.preview-title {
          font-weight: bold !important;
        }
      }

      .textarea {
        min-height: unset;
        height: 100px;
        box-shadow: $box-shadow;
      }

      .input,
      .textarea {
        &.is-danger {
          border-color: #f14668 !important;
        }
      }
    }
  }
}

.input {
  &.preview-title {
    font-weight: bold;
    font-size: 18px;
    color: $blue;
  }
}
</style>
