<template>
  <div class="question__footer">
    <div
      :class="isHiddenClass"
      class="question__footer-required"
    >
      Obligatoria
      <b-switch
        v-model="task.required"
        :value="false"
        type="is-success"
        :class="requiredClass"
      />
    </div>
    <div class="question__footer-actions">
      <div class="question__footer-store">
        <button class="cancel">
          Cancelar
        </button>
        <button class="save">
          Guardar
        </button>
      </div>

      <div class="question__footer-edition">
        <b-tooltip
          label="Editar"
          position="is-bottom"
          type="is-dark"
          :delay="300"
        >
          <button class="edit" />
        </b-tooltip>

        <b-tooltip
          v-if="!isFirstTask"
          label="Subir"
          position="is-bottom"
          type="is-dark"
          :delay="300"
        >
          <button
            class="move moveUp"
            @click="moveTask(task, 'up')"
          />
        </b-tooltip>

        <b-tooltip
          v-if="!isLastTask"
          label="Bajar"
          position="is-bottom"
          type="is-dark"
          :delay="300"
        >
          <button
            class="move moveDown"
            @click="moveTask(task, 'down')"
          />
        </b-tooltip>

        <b-tooltip
          label="Eliminar"
          position="is-bottom"
          type="is-dark"
          :delay="300"
        >
          <button
            class="remove"
            @click="deleteTask(task)"
          />
        </b-tooltip>

        <b-dropdown
          v-if="!isSES"
          aria-role="list"
          position="is-bottom-right"
          :triggers="['hover']"
          @click.native.stop
        >
          <template #trigger>
            <button class="more-actions" />
          </template>
          <b-dropdown-item
            aria-role="listitem"
            class="action duplicate"
            @click="duplicateTask(task)"
          >
            <img src="../assets/question/icon-question-duplicate.svg"><span>Duplicar</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isSetTaskMedia"
            aria-role="listitem"
            class="action delete-media"
            @click="deleteMedia()"
          >
            <img src="../assets/question/icon-question-delete-hover.svg"><span>Eliminar media</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isDescriptionVisible"
            aria-role="listitem"
            class="action show-description"
            @click="showDescription()"
          >
            <img src="../assets/question/icon-question-description.svg"><span>Sumar descripción</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import SharedConstants from '@/shared/constants.json'

export default {
  name: 'ActivityOpenQuestionItemFooter',
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    isDescriptionVisible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isSetTaskMedia() {
      return this.task.mediaFile != null
    },

    isFirstTask() {
      return this.index === 0
    },

    isLastTask() {
      return this.index === this.total - 1
    },

    requiredClass() {
      return this.task.required ? 'active' : ''
    },

    isSES() {
      return this.task.taskType === SharedConstants.TASK_TYPE.SES
    },

    isHiddenClass() {
      return this.task.taskType === SharedConstants.TASK_TYPE.SES ? 'hidden' : ''
    }
  },

  methods: {
    // Trigger Custom Event to DuplicateTask
    duplicateTask(task) {
      this.$parent.$emit('duplicate-task', task)
    },

    // Trigger Custom Event to DeleteTask
    deleteTask(task) {
      this.$parent.$emit('delete-task', task)
    },

    // Trigger Custom Event to moveTask
    moveTask(task, direction) {
      this.$parent.$emit('move-task', task, direction)
    },

    deleteMedia(task) {
      this.$emit('delete-media', task)
    },

    showDescription(task) {
      this.$emit('show-description', task)
    }
  }
}
</script>

<style lang="scss" scoped>
.question__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .question__footer-required {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    font-size: 16px;
    line-height: 20px;
    border-radius: 6px;
    border: 1px solid $gray-light;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.025);
    padding: 7px 10px 7px 22px;
    user-select: none;

    &.hidden {
      visibility: hidden;
    }

    label {
      margin-left: 15px;
    }
  }

  .question__footer-actions {
    .question__footer-store {
      display: none; // Temporal

      button {
        width: 100px;
        height: 35px;
        border-radius: 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        cursor: pointer;

        &.cancel {
          background: $white;
          border: 1px solid $blue;
          color: $blue;
        }

        &.save {
          border: none;
          margin-left: 13px;
          background: $blue;
          color: $white;

          &.disabled {
            background: #d2d2d2;
            color: $white;
          }
        }
      }
    }

    .question__footer-edition {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        width: 40px;
        height: 40px;
        background-color: $white;
        margin-left: 12px;
        border: 1px solid #E1E1E1;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &:active,
        &:focus {
          outline: none;
        }

        &.edit {
          display: none; // Temporal
          background: $blue-ultralight
            url("../assets/question/icon-question-edit.svg") center center no-repeat;

          &:hover {
            background: $blue-light
              url("../assets/question/icon-question-edit.svg") center center no-repeat;
          }
        }

        &.remove {
          background: url("../assets/question/icon-question-delete.svg") center center no-repeat;

          &:hover {
            background: url("../assets/question/icon-question-delete-hover.svg") center center no-repeat;
          }
        }

        &.move {
          background: url("../assets/question/icon-question-arrow.svg") center center no-repeat;

          &:hover {
            background: url("../assets/question/icon-question-arrow.svg") center center no-repeat;
          }

          &.moveDown {
            transform: rotate(180deg);
          }
        }

        &.more-actions {
          background: url("../assets/question/icon-question-points.svg") center center no-repeat;
        }
      }

      .action {
        img {
          position: absolute;
          width: 18px;
          height: 18px;
          margin-top: 2px;
        }

        span {
          margin-left: 28px;
        }

        &.show-description {
          img {
            height: 14px;
            width: 14px;
            top: 11px;
            left: 14px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.question__footer-edition {
  .dropdown {
    .dropdown-menu {
      .dropdown-content {
        width: 12rem;
        padding-bottom: 0;
        padding-top: 0;
        border-radius: 6px;

        .dropdown-item {
          padding: 10px 12px 10px 12px;
          font-size: 14px;
          color: $black;
          border-bottom: 1px solid #f3f3f3;
          transition: background-color 0.2s ease-in-out;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

</style>
