<template>
  <div
    :class="mainClass"
    class="choices"
  >
    <div
      v-if="!isRating"
      class="rotate-options-positions"
    >
      Rotar el orden de las opciones
      <b-switch
        v-model="task.rotateOptionsPosition"
        :value="false"
        type="is-success"
        :class="rotateOptionsPositionClass"
      />
    </div>
    <template v-if="!task.options.length">
      <ValidationProvider
        v-slot="{ errors, classes }"
        rules="requiredOption"
        name="OptionsNone"
      >
        <p :class="classes">
          Aún no hay opciones
        </p>
        <span class="error-label">{{ errors[0] }}</span>
      </ValidationProvider>
    </template>
    <template v-else>
      <div
        v-for="(item, indexOption) in task.options"
        :key="indexOption"
      >
        <QuestionOption
          ref="option"
          :option.sync="task.options[indexOption]"
          :task-index="index"
          :index="indexOption"
          :is-rating="isRating"
          :icon-rating="task.icons"
          @delete-option="deleteOption"
        />
      </div>
    </template>

    <div class="option-buttons">
      <button
        class="button is-text"
        @click.prevent="addTaskOption"
      >
        Añadir opción
      </button>
      <template v-if="showAddCustomOption">
        o
        <button
          class="button is-text"
          @click.prevent="addCustomOption"
        >
          permitir respuesta libre
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import QuestionOption from '@/components/ActivityOpenQuestionItemOption.vue'
import sharedConstants from '@/shared/constants.json'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ActivityOpenQuestionItemOptions',
  components: {
    QuestionOption,
    ValidationProvider
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    customOptionActive() {
      return this.task.options.some((option) => option.type === 'custom')
    },
    isSimpleChoice() {
      return this.task.taskType === sharedConstants.TASK_TYPE.SIMPLE_CHOICE
    },
    isMultipleChoice() {
      return this.task.taskType === sharedConstants.TASK_TYPE.MULTIPLE_CHOICE
    },
    mainClass() {
      let type = 'single-choice'

      if (this.isSimpleChoice) {
        type = 'single-choice'
      } else if (this.isMultipleChoice) {
        type = 'multi-choice'
      } else if (this.isRating) {
        type = 'rating-choice'
      }

      return type
    },
    isSetTaskMedia(index) {
      return this.task.options[index].mediaFile != null
    },
    isRating() {
      return this.task.taskType === sharedConstants.TASK_TYPE.RATING
    },
    rotateOptionsPositionClass() {
      return this.task.rotateOptionsPosition ? 'active' : ''
    },
    showAddCustomOption() {
      return [
        sharedConstants.TASK_TYPE.SIMPLE_CHOICE,
        sharedConstants.TASK_TYPE.MULTIPLE_CHOICE
      ].includes(this.task.taskType) && !this.customOptionActive
    }
  },
  methods: {
    // Add new Task to local Object (not by API)
    addTaskOption() {
      let taskCount = this.task.options.length

      if (this.customOptionActive) {
        taskCount -= 1
      }

      const optionData = {
        order: taskCount + 1,
        type: 'option',
        description: '',
        details: ''
      }

      this.task.options.splice(taskCount, 0, optionData)
    },
    addCustomOption() {
      this.task.options.push({
        order: this.task.options.length + 1,
        type: 'custom',
        description: '',
        details: ''
      })
    },
    // Delete question's option/s when click "x"
    deleteOption(option) {
      if (option) {
        const optionIndex = this.task.options.indexOf(option)
        if (optionIndex !== -1) {
          this.task.options.splice(optionIndex, 1)

          // Eliminate custom option text if option removed
          if (option.type === 'custom') {
            if (this.task.customOptionText) {
              this.task.customOptionText = ''
            }
          }

          // Show Buefy toast
          this.$buefy.toast.open({
            duration: 700,
            message: 'Opción eliminada',
            position: 'is-top',
            type: 'is-success'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.choices {
  display: flex;
  flex-flow: column;

  .error-label {
    font-size: 13px;
    color: #f14668;
    display: block;
    margin-bottom: 10px;
    margin-left: 42px;
  }

  button.is-text {
    width: fit-content;
    display: inline-block;
    padding: 0;
    font-size: 16px;
    color: $blue;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:first-child {
      margin-right: 3px;
    }

    &:last-child {
      margin-left: 3px;
    }

    &:hover {
      background: transparent;
      text-decoration: underline;
      transition: all 0.2s ease-in-out;
      color: $blue;
    }

    &:active,
    &:focus {
      outline: none;
      background-color: transparent;
      box-shadow: none;
      color: $blue;
    }
  }
}

.multi-choice {
  .media-container {
    background: url("../assets/question/icon-question-checkbox.svg")
      left center no-repeat;
  }
}

.single-choice {
  .media-container {
    background: url("../assets/question/icon-question-radiobutton.svg")
      left center no-repeat;
  }
}

.rating-choice {
  .wrap-input {
    &:before {
      content: attr(data-row) ".";
      position: absolute;
      left: 7px;
      top: 2px;
      color: black;
      font-weight: 500;
    }
  }
}

.multi-choice,
.single-choice,
.rating-choice {
  .wrap-input {
    position: relative;
    display: flex;

    .media-container {
      background-size: 25px;
      width: 28px;
      height: 28px;
      display: inline-flex;
    }

    .layout-right {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;

      .layout-right-first-row {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
      }

      input {
        border-radius: 0px;
        padding-left: 0px;
        padding-right: 47px;
        margin: 0px 10px 0px 13px;
        border: none;
        border-bottom: 1px solid transparent;
        box-shadow: 0 0 black;
        height: 28px;
        color: $black;
        display: inline-flex;
        width: 100%;
        transition: border-bottom 0.4s ease-in-out;

        &:focus,
        &:active {
          color: $black;
        }

        &.is-error {
          margin-bottom: 2px;
          border-radius: 0px;
          border-bottom: 1px solid #f14668;
        }
      }

      .btnDeleteOption {
        background: url("../assets/question/icon-question-option-delete.svg") left center no-repeat;
        background-size:  12px 12px;
        height: 12px;
        width: 12px;
        cursor: pointer;
        font-size: 22px;
        font-weight: 500;
        transition: opacity 0.3s ease-in-out;
        opacity: 0.6;
      }

      input {
        border-bottom: 1px solid $gray-light;
        transition: border-bottom 0.2s ease-in-out;
      }

      .dropdown {
        display: flex;
        opacity: 1;
      }
    }
  }
}

.rotate-options-positions {
  width : 100%;
  height: 40px;
  display: flex;
  color: $black;
  font-size: 16px;
  line-height: 20px;
  border-radius: 6px;
  border: 1px solid $gray-light;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.025);
  padding: 7px 10px 7px 22px;
  user-select: none;
  margin-bottom: 10px;

  &.hidden {
    visibility: hidden;
  }

  label {
    margin-left: auto;
  }
}

.option-buttons {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
  .choices {
    button {
      &.is-text {
        color: #3a6cff;
        &:hover {
          background-color: transparent;
        }
      }
    }

    span.custom {
      input[type="text"], input[type="text"]::placeholder {
        color: $blue !important;
      }
    }

    .layout-right {

      .dropdown {
        align-items: flex-start;

        .dropdown-menu {
          top: 30px;

          .dropdown-content {
            border-radius: 6px;
            padding-bottom: 0;
            padding-top: 0;

            .dropdown-item {
              font-size: 13.5px;
              color: $black;
              padding: 10px 11px 10px 11px;
              border-bottom: 1px solid #f3f3f3;
              transition: background-color 0.2s ease-in-out;

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                background-color: #fbfbfb !important;
              }

              img {
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }
</style>
