<template>
  <div class="general-container">
    <the-navbar />
    <activity-open-question />
    <activity-navbar class="float-bottom-navbar" />
  </div>
</template>

<script>
// @ is an alias to /src
import TheNavbar from '@/components/TheNavbar.vue'
import ActivityOpenQuestion from '@/components/ActivityOpenQuestion.vue'
import ActivityNavbar from '@/components/ActivityNavbar.vue'

export default {
  name: 'Actividad',
  components: {
    TheNavbar,
    ActivityOpenQuestion,
    ActivityNavbar
  }
}
</script>

<style lang="scss" scoped>
.general-container {
  .float-bottom-navbar {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 23px;
  }
}
</style>
