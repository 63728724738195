<template>
  <div>
    <div class="question__form-type">
      <!--------------------
      // RESPUESTA ABIERTA
      --------------------->
      <div v-if="isOpenQuestion">
        <div class="open_question_response_type">
          Respuesta con media
          <b-switch
            v-model="isResponseWithMedia"
            :value="false"
            type="is-success"
            :class="responseWithMediaClass"
          />
        </div>
        <div
          v-if="isResponseWithMedia"
          class="response-media-limit"
        >
          <p>Número de archivos de media a subir:</p>
          <button
            class="subtract"
            @click="subtractMedia()"
          />
          <p class="limit">
            {{ task.responseMediaLimit }}
          </p>
          <button
            class="add"
            @click="addMedia()"
          />
        </div>
        <div
          v-else
          class="free-text"
        >
          <b-field>
            <b-input
              maxlength="500"
              type="textarea"
              placeholder="Respuesta libre (Máximo 500 caracteres)"
              disabled
            />
          </b-field>
        </div>
      </div>

      <!---------------------------------------
      // OPCION SIMPLE | MULTIPLE | PUNTUACION
      ---------------------------------------->
      <QuestionOptions
        v-if="isChoice"
        :task.sync="task"
      />

      <!--------------------
      // NPS
      --------------------->
      <div v-if="isNPS">
        <div class="nps">
          <template>
            <ValidationProvider
              v-slot="{ errors, classes }"
              rules="required"
              name="nps-description-1"
            >
              <b-field
                label="Valor 1:"
                horizontal
                :type="{ 'is-danger': errors[0] }"
                :message="{
                  'Debes ingresar un valor para la opción 1': errors[0],
                }"
              >
                <b-input
                  v-model="task.options[0].details"
                  name="description-1"
                  :class="classes"
                  placeholder="Ingresá un valor"
                  custom-class="details-value-input"
                />
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, classes }"
              rules="required"
              name="nps-description-10"
            >
              <b-field
                label="Valor 10:"
                horizontal
                :type="{ 'is-danger': errors[0] }"
                :message="{
                  'Debes ingresar un valor para la opción 10': errors[0],
                }"
              >
                <b-input
                  v-model="task.options[9].details"
                  name="description-10"
                  :class="classes"
                  placeholder="Ingresá un valor"
                  custom-class="details-value-input"
                />
              </b-field>
            </ValidationProvider>
            <TaskNPS :task="task" />
          </template>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionOptions from '@/components/ActivityOpenQuestionItemOptions.vue'
import SharedConstants from '@/shared/constants.json'
import TaskNPS from '@/components/open-questionnaire/task-nps/TaskNPS.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ActivityOpenQuestionItemDetail',
  components: {
    QuestionOptions,
    TaskNPS,
    ValidationProvider
  },
  props: {
    task: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },

  computed: {
    isOpenQuestion() {
      return this.task.taskType === SharedConstants.TASK_TYPE.OPEN_QUESTION
    },

    isChoice() {
      return this.task.taskType === SharedConstants.TASK_TYPE.SIMPLE_CHOICE
        || this.task.taskType === SharedConstants.TASK_TYPE.MULTIPLE_CHOICE
        || this.task.taskType === SharedConstants.TASK_TYPE.RATING
    },

    isNPS() {
      return this.task.taskType === SharedConstants.TASK_TYPE.NPS
    },

    isResponseWithMedia: {
      get() {
        return this.task.responseAllowedType === SharedConstants.TASK_RESPONSE_TYPE.ONLY_MEDIA
      },

      set(value) {
        if (value) {
          this.task.responseAllowedType = SharedConstants.TASK_RESPONSE_TYPE.ONLY_MEDIA
          this.task.responseMediaLimit = 1
        } else {
          this.task.responseAllowedType = SharedConstants.TASK_RESPONSE_TYPE.ONLY_TEXT
          this.task.responseMediaLimit = 0
        }
      }
    },

    responseWithMediaClass() {
      return this.isResponseWithMedia ? 'active' : ''
    }
  },

  methods: {
    addMedia() {
      if (this.task.responseMediaLimit < 6) {
        this.task.responseMediaLimit += 1
      }
    },

    subtractMedia() {
      if (this.task.responseMediaLimit > 1) {
        this.task.responseMediaLimit -= 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question__form-type {
  margin-bottom: 20px;

  > div {
    width: 100%;

    .open_question_response_type {
      width : 100%;
      height: 40px;
      display: flex;
      color: $black;
      font-size: 16px;
      line-height: 20px;
      border-radius: 6px;
      border: 1px solid $gray-light;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.025);
      padding: 7px 10px 7px 22px;
      user-select: none;
      margin-bottom: 10px;

      &.hidden {
        visibility: hidden;
      }

      label {
        margin-left: auto;
      }
    }

    .response-media-limit {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      p {
        font-size: 16px;
        font-weight: 500;
        color: $black;
        margin-right: 16px;

        &.limit {
          width: 10px;
          margin-left: 16px;
        }
      }

      button {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid #E1E1E1;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &.add {
          background: url("../assets/question/icon-question-arrow-rigth-blue.svg") center center no-repeat;
        }

        &.subtract {
          background: url("../assets/question/icon-question-arrow-left-blue.svg") center center no-repeat;
        }
      }
    }

    .free-text {
      > div {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.nps {
  .field-body {
    .field {
      .control {
        input {
          background: url("../assets/profile/icon-profile-input-edit.svg") 98% 60% no-repeat;
          background-size: 15px;
          padding-right: 25px;
          transition: all .1s ease-in-out;

          &:hover, &:focus {
            border-bottom: 1px solid $gray-light !important;
            transition: all .1s ease-in-out;
          }
        }

        .has-text-danger {
          visibility: hidden;
        }
      }
    }
  }

  .task-nps {
    margin-top: 10px;
  }
}
</style>
