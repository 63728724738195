<template>
  <ValidationProvider
    v-slot="{ errors, classes }"
    rules="required"
    :class="{ 'option-has-media': isSetOptionMedia, custom: option.type === 'custom' }"
    :name="'options-' + taskIndex + '-' + index"
  >
    <div
      class="wrap-input"
      :class="classes"
      :data-row="index+1"
    >
      <media
        :model="option"
        type="option"
        :media-width="100"
      />

      <div class="layout-right">
        <div class="layout-right-first-row">
          <input
            ref="input"
            v-model="option.description"
            type="text"
            class="input"
            :class="{ 'is-error': errors[0] }"
            :name="'option-' + index"
            :placeholder="option.type === 'custom' ? 'Nombre de la opción de respuesta libre' : 'Nombre de la opción'"
          >
          <b-dropdown
            v-if="option.type !== 'custom'"
            aria-role="list"
            position="is-bottom-right"
            @click.native.stop
          >
            <template #trigger>
              <button class="more-actions" />
            </template>
            <b-dropdown-item
              aria-role="listitem"
              class="action"
              :class="{ 'disabled': isMediaLoading }"
              @click="openMediaWidget"
            >
              <img src="../assets/question/icon-question-media-hover.svg"><span>{{ addMediaText }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isSetOptionMedia"
              aria-role="listitem"
              class="action"
              @click="unsetMedia"
            >
              <img src="../assets/question/icon-question-media.svg"><span>Eliminar media</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!isDescriptionVisible"
              aria-role="listitem"
              class="action show-description"
              @click="showDescription"
            >
              <img src="../assets/question/icon-question-description.svg"><span>Agregar descripción</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isDescriptionVisible"
              aria-role="listitem"
              class="action"
              @click="removeDescription"
            >
              <img src="../assets/question/icon-question-delete-hover.svg"><span>Quitar descripción</span>
            </b-dropdown-item>
          </b-dropdown>
          <div
            class="btnDeleteOption"
            @click="deleteOption()"
          />
        </div>
        <div
          v-if="isDescriptionVisible"
          class="question__form-description question__form-description-option"
        >
          <b-field>
            <b-input
              :ref="'formdescription-' + index"
              :key="'description-input-' + index"
              v-model="option.details"
              type="textarea"
              maxlength="200"
              placeholder="Ingresá una descripción (Opcional)"
              custom-class="form-description"
              :name="'description-' + index"
            />
          </b-field>
        </div>
      </div>

      <div
        v-if="isRating"
        class="option-rating-preview"
        :class="iconRating"
      />
    </div>
    <span class="error-label">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import CloudinaryTexts from '@/cloudinaryTexts.json'
import Media from '@/components/Media.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ActivityOpenQuestionItemOption',
  components: {
    Media,
    ValidationProvider
  },
  props: {
    option: {
      type: Object,
      default: null
    },
    taskIndex: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    isRating: {
      type: Boolean,
      default: false
    },
    iconRating: {
      type: String,
      default: 'star'
    }
  },

  data() {
    return {
      isDescriptionVisible: false,
      isMediaLoading: false
    }
  },

  computed: {
    isSetOptionMedia() {
      return this.option.mediaFile != null
    },

    isSetOptionImage() {
      return this.option.mediaFile != null && this.option.mediaFile.resourceType === 'image'
    },

    isSetOptionVideo() {
      return this.option.mediaFile != null && this.option.mediaFile.resourceType === 'video'
    },

    addMediaText() {
      return this.isSetOptionMedia ? 'Cambiar media' : 'Agregar media'
    }
  },

  mounted() {
    this.isDescriptionVisible = this.option.details !== ''
  },

  methods: {
    deleteOption() {
      this.$emit('delete-option', this.option)
    },

    showDescription() {
      this.isDescriptionVisible = true
    },

    removeDescription() {
      this.option.details = ''
      this.isDescriptionVisible = false
    },

    unsetMedia() {
      this.option.mediaFile = null
    },

    setOptionMedia(mediaInfo) {
      this.$set(this.option, 'mediaFile',
        {
          publicId: mediaInfo.public_id,
          width: mediaInfo.width,
          height: mediaInfo.height,
          type: mediaInfo.type,
          resourceType: mediaInfo.resource_type,
          version: mediaInfo.version,
          format: mediaInfo.format
        })
    },

    openMediaWidget() {
      this.isMediaLoading = true
      window.cloudinary.createUploadWidget({
        upload_preset: 'mvp_preset',
        folder: 'option-media',
        cropping: true,
        multiple: false,
        language: 'es',
        text: CloudinaryTexts
      }, (error, result) => {
        this.enableMediaButton()
        if (!error && result && result.event === 'success') {
          this.setOptionMedia(result.info)
        }
      }).open()
    },

    setFocus() {
      this.$refs.input.focus()
    },

    enableMediaButton() {
      this.isMediaLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.option-has-media {
  .error-label {
    margin-left: 74px;
  }
}

.button-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 7px;
  align-items: flex-start;
  height: 14px;
}

.wrap-input {
  min-height: 28px;
  margin-bottom: 12px;

  .dropdown {
    position: relative;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  ::v-deep .dropdown-trigger {
    display: flex;
    height: 28px;
    align-items: center;
  }

  button {
    &.more-actions {
      width: 28px;
      height: 14px;
      background-color: $white;
      border: none;
      cursor: pointer;
      padding: 0;

      &:active,
      &:focus {
        outline: none;
      }

      background: url("../assets/question/icon-question-points.svg") center center no-repeat;
      background-size: contain;
    }
  }

  .action {
    img {
      position: absolute;
      width: 18px;
      height: 18px;
    }

    span {
      margin-left: 28px;
    }

    &.show-description {
      img {
        height: 14px;
        width: 14px;
        top: 10px;
        left: 14px;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .option-rating-preview {
    width: 293px;
    height: 30px;
    margin-left: 20px;

    &.star {
      background: url("../assets/question/icon-question-rating-group-star.svg") center center no-repeat;
    }

    &.like {
      background: url("../assets/question/icon-question-rating-group-like.svg") center center no-repeat;
    }

    &.radio_button {
      background: url("../assets/question/icon-question-rating-group-radio_button.svg") center center no-repeat;
    }

    &.heart {
      background: url("../assets/question/icon-question-rating-group-heart.svg") center center no-repeat;
    }
  }
}

.question__form-description {
  &.question__form-description-option {
    width: 100%;
    margin-top: 10px;
    padding-right: 40px;

    .field {
      margin-left: 13px !important;
      margin-right: 0px !important;

      .textarea {
        &.form-description {
          font-size: 15px !important;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
