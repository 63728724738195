var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"question__form-type"},[(_vm.isOpenQuestion)?_c('div',[_c('div',{staticClass:"open_question_response_type"},[_vm._v(" Respuesta con media "),_c('b-switch',{class:_vm.responseWithMediaClass,attrs:{"value":false,"type":"is-success"},model:{value:(_vm.isResponseWithMedia),callback:function ($$v) {_vm.isResponseWithMedia=$$v},expression:"isResponseWithMedia"}})],1),(_vm.isResponseWithMedia)?_c('div',{staticClass:"response-media-limit"},[_c('p',[_vm._v("Número de archivos de media a subir:")]),_c('button',{staticClass:"subtract",on:{"click":function($event){return _vm.subtractMedia()}}}),_c('p',{staticClass:"limit"},[_vm._v(" "+_vm._s(_vm.task.responseMediaLimit)+" ")]),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.addMedia()}}})]):_c('div',{staticClass:"free-text"},[_c('b-field',[_c('b-input',{attrs:{"maxlength":"500","type":"textarea","placeholder":"Respuesta libre (Máximo 500 caracteres)","disabled":""}})],1)],1)]):_vm._e(),(_vm.isChoice)?_c('QuestionOptions',{attrs:{"task":_vm.task},on:{"update:task":function($event){_vm.task=$event}}}):_vm._e(),(_vm.isNPS)?_c('div',[_c('div',{staticClass:"nps"},[[_c('ValidationProvider',{attrs:{"rules":"required","name":"nps-description-1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-field',{attrs:{"label":"Valor 1:","horizontal":"","type":{ 'is-danger': errors[0] },"message":{
                'Debes ingresar un valor para la opción 1': errors[0],
              }}},[_c('b-input',{class:classes,attrs:{"name":"description-1","placeholder":"Ingresá un valor","custom-class":"details-value-input"},model:{value:(_vm.task.options[0].details),callback:function ($$v) {_vm.$set(_vm.task.options[0], "details", $$v)},expression:"task.options[0].details"}})],1)]}}],null,false,1843410089)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"nps-description-10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var classes = ref.classes;
return [_c('b-field',{attrs:{"label":"Valor 10:","horizontal":"","type":{ 'is-danger': errors[0] },"message":{
                'Debes ingresar un valor para la opción 10': errors[0],
              }}},[_c('b-input',{class:classes,attrs:{"name":"description-10","placeholder":"Ingresá un valor","custom-class":"details-value-input"},model:{value:(_vm.task.options[9].details),callback:function ($$v) {_vm.$set(_vm.task.options[9], "details", $$v)},expression:"task.options[9].details"}})],1)]}}],null,false,2993467184)}),_c('TaskNPS',{attrs:{"task":_vm.task}})],_c('br')],2)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }