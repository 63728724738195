<template>
  <div class="container is-widescreen">
    <div class="columns is-centered">
      <div class="column is-8">
        <div
          class="activity-navbar"
          :class="{ hidden: isLastStep }"
        >
          <button
            class="cancel"
            @click="prevStep"
          >
            Anterior
          </button>

          <button
            class="continue"
            @click="nextStep"
          >
            {{ labelButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ActivityNavbar',
  computed: {
    ...mapGetters('Projects', ['isCommunityProject']),
    ...mapState('Projects', ['projectData']),
    ...mapState('staffActivities', ['step']),
    isLastStep() {
      if (this.step === 4) {
        return true
      }
      return false
    },
    labelButton() {
      if (this.step === 3) {
        if (this.isCommunityProject) {
          return 'Volver al proyecto'
        }

        return 'Publicar Proyecto'
      }

      return 'Continuar'
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },

    prevStep() {
      if (this.step === 1) {
        if (this.isCommunityProject) {
          this.$router.push({
            name: 'project-dashboard-summary',
            params: {
              projectIdentifier: this.projectData.identifier
            }
          })
        } else {
          this.$router.push({ name: 'proyectos' })
        }
      }

      if (this.step > 1) {
        this.$root.$emit('validateStepFields')
      }
    },

    nextStep() {
      if (this.step === 3 && this.isCommunityProject) {
        this.$router.push({
          name: 'project-dashboard-summary',
          params: { projectIdentifier: this.projectData.identifier }
        })
      } else {
        this.$root.$emit('updateActivity')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-navbar {
  height: 60px;
  background: $white;
  border: 1px solid $gray-ultralight;
  box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.hidden {
    display: none;
  }

  button {
    padding: 0 24px;
    min-width: 130px;
    height: 35px;
    border-radius: 17.5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    &.cancel {
      background: $white;
      border: 1px solid #2b2323;
      color: $black;
      margin-left: 14px;

      &:hover {
        border: 1px solid $gray;
        transition: $buttonBackgroundTransition;
      }
    }

    &.continue {
      color: $white;
      background: $blue;
      box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.3);
      border: none;
      margin-right: 14px;

      &:hover {
        background-color: $blue-hover;
        transition: $buttonBackgroundTransition;
      }
    }
  }
}
</style>
